import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import {AccountType} from "./common/api/model/account/AccountType";
import {Authorization} from "./api/model/account/Authorization";
import PrivateRoute from "./component/guard/PrivateRoute";
import {
    Accounts,
    ActivateService,
    ApiTokens,
    BillingInfo,
    BillingInvoices,
    ConfirmEmail,
    Credentials,
    ForgottenPassword,
    Home,
    Information,
    InvitationAccept,
    LegalNotice,
    Login,
    Register,
    RegisterBilling,
    RegisterInfo,
    RegisterOrigin,
    ResetPassword,
    TermsOfUse,
    TrafficDaily,
    TrafficGlobal
} from "./screen";
import {
    pathAccessAccounts,
    pathAccessApiTokens,
    pathActivateService,
    pathBillingInfo,
    pathBillingInvoices,
    pathConfirmEmail,
    pathCredentials,
    pathForgottenPassword,
    pathInformation,
    pathInvitationAccept,
    pathRegister,
    pathRegisterBilling,
    pathRegisterInfo,
    pathRegisterOrigin,
    pathResetPassword,
    pathTermsOfUse,
    pathTrafficDaily,
    pathTrafficGlobal
} from "./utils/constants";
import {pathHome, pathLegalNotice, pathLogin} from "./common/utils/constants";
import {session} from "./api/ApiSession";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {PortalContextProvider} from "./common/context/PortalContext";
import "./utils/ChartJS";

const history = createBrowserHistory();

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/" component={() => <Redirect to={pathLogin}/>}/>
                            <Route exact path={pathLogin} component={Login}/>
                            <Route exact path={pathForgottenPassword} component={ForgottenPassword}/>
                            <Route exact path={pathResetPassword} component={ResetPassword}/>
                            <Route exact path={pathRegister} component={Register}/>
                            <Route exact path={pathInvitationAccept} component={InvitationAccept}/>
                            <Route exact path={pathConfirmEmail} component={ConfirmEmail}/>
                            <Route exact path={pathLegalNotice} component={LegalNotice}/>
                            <Route exact path={pathTermsOfUse} component={TermsOfUse}/>

                            <PrivateRoute
                                exact
                                path={pathRegisterInfo}
                                component={RegisterInfo}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathRegisterOrigin}
                                component={RegisterOrigin}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathRegisterBilling}
                                component={RegisterBilling}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />

                            <PrivateRoute
                                exact
                                path={pathHome}
                                component={Home}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathInformation}
                                component={Information}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathCredentials}
                                component={Credentials}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathAccessAccounts}
                                component={Accounts}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathAccessApiTokens}
                                component={ApiTokens}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathTrafficDaily}
                                component={TrafficDaily}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={Authorization.TRAFFIC}
                            />
                            <PrivateRoute
                                exact
                                path={pathTrafficGlobal}
                                component={TrafficGlobal}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={Authorization.TRAFFIC}
                            />
                            <PrivateRoute
                                exact
                                path={pathBillingInfo}
                                component={BillingInfo}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={undefined}
                            />
                            <PrivateRoute
                                exact
                                path={pathBillingInvoices}
                                component={BillingInvoices}
                                restrictedAccountType={AccountType.USER}
                                requiredAuthorization={undefined}
                            />

                            <PrivateRoute
                                exact
                                path={pathActivateService}
                                component={ActivateService}
                                restrictedAccountType={undefined}
                                requiredAuthorization={undefined}
                            />

                            <Route component={() => <Redirect to={pathLogin}/>}/>
                        </Switch>
                    </Router>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;
