import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import validate from "validate.js";
import {
    AlertSeverity,
    Box,
    BoxSpacing,
    ButtonSave,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    InputAutocomplete,
    InputPhone,
    InputPhoneLocale,
    InputText,
    SelectAutocomplete,
    SelectOption
} from "@sirdata/ui-lib";

import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import useRegister from "../../utils/hooks/useRegister";
import {session} from "../../api/ApiSession";
import {Country, CountryCode} from "../../common/api/model/Country";
import {pathRegister, pathRegisterInfo, TranslationPortalFile} from "../../utils/constants";
import {FormErrorMessage, FormSchema, FormState, LogoSirdataOnboarding} from "../../component/snippet";
import {Locale} from "../../common/utils/Locale";
import {EmailDomainInfos} from "../../api/model/partner-organization/EmailDomainInfos";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {PartnerOrganization} from "../../api/model/account/PartnerOrganization";
import {PartnerAccount} from "../../api/model/account/PartnerAccount";
import {FormLayoutMessage} from "../../common/component/snippet";

function RegisterInfo() {
    const history = useHistory();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const [registerer, {goToNext}] = useRegister(pathRegisterInfo);

    const [partnerAccount, setPartnerAccount] = useState<PartnerAccount>(new PartnerAccount());
    const [countries, setCountries] = useState<Country[]>([]);
    const [emailDomainInfos, setEmailDomainInfos] = useState(new EmailDomainInfos());
    const [knownOrganizationGroup, setKnownOrganizationGroup] = useState<string>();
    const [organizationGroup, setOrganizationGroup] = useState<string | undefined>();
    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [isShowErrors, setShowErrors] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!registerer.isOriginStepAllowed) {
            history.replace(pathRegister);
        } else {
            (async function () {
                try {
                    const countries = await session.getCountries();
                    setCountries(countries);

                    const account = await session.getPartnerAccount();
                    setPartnerAccount(account);

                    if (session.onboarding.shouldCreateOrganization) {
                        const emailDomainInfos = await session.getEmailDomainInfos(account.emailDomain);
                        setEmailDomainInfos(emailDomainInfos);

                        const domainOrganizationGroup = emailDomainInfos.domain_organization_group?.name;
                        setKnownOrganizationGroup(domainOrganizationGroup || account.partner.organization.getValidatedGroupName());
                        setOrganizationGroup(domainOrganizationGroup || account.partner.organization.group_name);
                    }
                } catch (e) {}
            })();
        }
    }, [history, registerer.isOriginStepAllowed]);

    useEffect(() => {
        setApiError("");
        const errors = validate(partnerAccount, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [partnerAccount]);

    const updatePartnerAccount = (name: string, value: string | number | undefined, forPartner?: boolean) => {
        setPartnerAccount((prevState) => {
            const state = new PartnerAccount();
            if (forPartner) {
                state.load(prevState);
                state.partner.load({...prevState.partner, [name]: value});
            } else {
                state.load({...prevState, [name]: value});
            }
            return state;
        });
    };

    const changePhone = (value: string) => updatePartnerAccount("phone", value);

    const changePhoneRegion = (countryCode?: string) => updatePartnerAccount("phone_region", countryCode);

    const handleChangeCompany = (option: SelectOption | undefined) => {
        if (!emailDomainInfos.isDomainOrganizationGroupExist()) {
            const existingOrganization = emailDomainInfos.organizations.find(({name}) => option?.value === name);
            setKnownOrganizationGroup(existingOrganization?.group_name);
            setOrganizationGroup(existingOrganization?.group_name);
        }

        updatePartnerAccount("company", option?.value as string, true);
    };

    const handleSubmit = async () => {
        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }

        try {
            setSubmitting(true);
            const account = await session.getPartnerAccount();
            account.load(partnerAccount);
            await session.restPartnerAccount.update(partnerAccount);

            if (session.onboarding.shouldCreateOrganization) {
                const createdOrganization = await session.restPartnerOrganization.create(new PartnerOrganization({name: partnerAccount.partner.company, group_name: organizationGroup}));
                account.partner.organization = new PartnerOrganization(createdOrganization);
            }

            goToNext();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.UNAUTHORIZED) {
                    history.replace(pathRegister);
                }
                if (e.statusCode === HttpStatusCode.BAD_REQUEST && e.message.includes("invalid phone number")) {
                    setShowErrors(true);
                    setFormState((prev) => ({...prev, isValid: false, errors: {...prev.errors, "phone": FormErrorMessage.PHONE_INVALID}}));
                } else {
                    setApiError(getApiErrorTranslationKey(e.message));
                }
            }
            setSubmitting(false);
        }
    };

    const hasEmptyField = () => !partnerAccount.last_name || !partnerAccount.first_name || !partnerAccount.partner.company || !partnerAccount.phone;

    const currentCountry = countries.find((country) => country.id === partnerAccount.partner.country_id);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="h1 title" dangerouslySetInnerHTML={{__html: textOnboarding("register_info.title", {step: session.onboarding.getCurrentStep(pathRegisterInfo), steps: session.onboarding.getTotalSteps()})}}/>
                    <div className="description">{textOnboarding("register_info.description")}</div>
                    <ContentBlock cssClass="register-form__block">
                        <Box spacing={BoxSpacing.XLARGE}>
                            <FormLayoutRows>
                                <FormLayoutColumns>
                                    <FieldBlock label={textOnboarding("form.field.last_name")} required>
                                        <InputText
                                            placeholder={textOnboarding("form.placeholder.last_name")}
                                            value={partnerAccount.last_name}
                                            onChange={(value) => updatePartnerAccount("last_name", value)}
                                            autoFocus
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textOnboarding("form.field.first_name")} required>
                                        <InputText
                                            placeholder={textOnboarding("form.placeholder.first_name")}
                                            value={partnerAccount.first_name}
                                            onChange={(value) => updatePartnerAccount("first_name", value)}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    {session.onboarding.shouldCreateOrganization ?
                                        <>
                                            <FieldBlock label={textOnboarding("form.field.company")} required>
                                                {emailDomainInfos.isDomainOrganizationGroupExist() || emailDomainInfos.domain_organizations.length ?
                                                    <SelectAutocomplete
                                                        placeholder={textOnboarding("form.placeholder.company")}
                                                        options={emailDomainInfos.buildOrganizationOptions()}
                                                        value={partnerAccount.partner.company}
                                                        onChange={handleChangeCompany}
                                                        creatable
                                                        clearable
                                                    /> :
                                                    <InputAutocomplete
                                                        placeholder={textOnboarding("form.placeholder.company")}
                                                        options={emailDomainInfos.buildOrganizationOptions()}
                                                        value={partnerAccount.partner.company}
                                                        onChange={handleChangeCompany}
                                                        creatable
                                                        clearable
                                                    />
                                                }
                                            </FieldBlock>
                                            <FieldBlock label={textOnboarding("form.field.organization_group")}>
                                                {knownOrganizationGroup
                                                    ? (
                                                        <InputText
                                                            value={knownOrganizationGroup}
                                                            onChange={() => {}}
                                                            disabled
                                                        />
                                                    )
                                                    : (
                                                        <InputAutocomplete
                                                            placeholder={textOnboarding("form.placeholder.organization_group")}
                                                            options={emailDomainInfos.buildOrganizationGroupOptions()}
                                                            value={organizationGroup}
                                                            onChange={(option) => setOrganizationGroup(option?.value as string)}
                                                            disabled={!partnerAccount.partner.company}
                                                            creatable
                                                            clearable
                                                        />
                                                    )
                                                }
                                            </FieldBlock>
                                        </> :
                                        <FieldBlock label={textOnboarding("form.field.company")} required>
                                            <InputText
                                                placeholder={textOnboarding("form.placeholder.company")}
                                                value={partnerAccount.partner.company}
                                                onChange={(value) => updatePartnerAccount("company", value, true)}
                                            />
                                        </FieldBlock>
                                    }
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock
                                        label={textOnboarding("form.field.phone")}
                                        content={{direction: FlexContentDirection.COLUMN}}
                                        required
                                    >
                                        <InputPhone
                                            placeholder={textOnboarding("form.placeholder.phone")}
                                            value={partnerAccount.phone}
                                            onChange={changePhone}
                                            locale={Locale.isFrench() ? InputPhoneLocale.FRENCH : InputPhoneLocale.ENGLISH}
                                            country={partnerAccount.phone_region}
                                            onCountryChange={changePhoneRegion}
                                        />
                                        {!!(isShowErrors && formState.errors.phone) &&
                                            <FormLayoutMessage message={t(`error.${formState.errors.phone}`)} severity={AlertSeverity.DANGER}/>
                                        }
                                    </FieldBlock>
                                    <FieldBlock label={textOnboarding("form.field.country")} required>
                                        <SelectAutocomplete
                                            onChange={(option) => updatePartnerAccount("country_id", option?.value as number, true)}
                                            options={countries.map((country) => ({value: country.id, label: country.name}))}
                                            value={currentCountry?.id || CountryCode.FRANCE.id}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutMention/>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                    <FormLayoutButtons>
                                        <ButtonSave disabled={hasEmptyField()} onClick={handleSubmit} loading={isSubmitting}/>
                                    </FormLayoutButtons>
                                    {apiError &&
                                        <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                    }
                                </FlexContent>
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default RegisterInfo;
